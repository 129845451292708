import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {useMediaQuery} from '@react-hook/media-query'
import Analysis from './Analysis';
import { accountBalance, referralBalance, bonusBalance, currentInvestment } from '../Request/Wallets';
// import {currentEarning} from '../Request/Wallets';
import { MiniChart } from "react-ts-tradingview-widgets";
import Chart from 'react-apexcharts'
import InvestmentHistory from '../Investment/InvestmentHistory';
import ChartBalance from '../Reinvestment/ChartBalance';

const Index = () => {

    const switchEye = (name) => {
        let element = document.getElementById(name);
        let eye  = element.innerHTML;
        console.log(eye);
        if (eye === 'visibility') {
            element.innerHTML = 'visibility_off';
        }else{
            element.innerHTML = 'visibility';
        }

    }

    const initialChart = {
          
        series: [{
            name: 'Earnings',
            data: [31, 40, 28, 51, 42, 50, 60]
          }, {
            name: 'Percentages',
            data: [11, 32, 45, 32, 34, 52, 41]
          }],
          options: {
            chart: {
              height: 150,
              type: 'area',
              toolbar: {
                autoSelected: "pan",
                show: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
                show: false,
            },
            xaxis: {
                labels: {
                    show: false
                },
              type: 'datetime',
              categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            yaxis: {
                show: false
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
          
    }

    const initialDonut = {
        series2: [44],
        options2: {
          chart: {
            type: 'donut',
          },
          colors: ['#4fb990'],
          plotOptions: {
            pie: {                
            startAngle: 0,
            endAngle: 360,
              donut: {
                size: '80%'
              }
            }
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
        },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
    }


    const initialDonut2 = {
        series3: [44],
        options3: {
          chart: {
            type: 'donut',
          },
          colors: ['#00923f'],
          plotOptions: {
            pie: {                
            startAngle: -90,
            endAngle: 90,
              donut: {
                size: '95%'
              }
            }
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
        },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
    }
    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());
    const [currentInvested, setcurrentInvested] = useState(currentInvestment());
    const [chartData, setChartData] = useState(initialChart);
    const [donutData, setDonutData] = useState(initialDonut);
    const [donutBalance, setDonutBalance] = useState(initialDonut2);


       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.ref_bal);
        }
        return balance;
    
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }

      const newCurrentInvestment = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.current_investment;
        }
        return balance;
      }


    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let theme = localStorage.getItem('TURBO TRADING LIMITED-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    const matches = useMediaQuery('only screen and (max-width: 480px)')
    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('TURBO TRADING LIMITED-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        setreferralBalance(newreferralBalance());
        setbonusBalance(newbonusBalance());
        setcurrentInvested(newCurrentInvestment());
        
  }, false);
    });
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

const {options, series} = chartData;
const {options2, series2} = donutData;
const {options3, series3} = donutBalance;
   return (
<div id="page-content">    
{accountType !== 'admin' && 
    <div className="content py-2" style={{marginTop: '0px', marginBottom: '0px'}}>
        <div className="d-flex text-center">
            <div className="me-auto">
                <Link to="/invest" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>analytics</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Invest</h6>
            </div>
            <div className="m-auto">
                <Link to="/withdraw" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>money_off_csred</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Withdraw</h6>
            </div>
            <div className="m-auto">
                <Link to="/referral" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>diversity_2</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Referral</h6>
            </div>
            <div className="ms-auto">
                <Link to="/market" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>candlestick_chart</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">market</h6>
            </div>
        </div>
    </div> 
    }
    <ChartBalance/>
    <Analysis/>
    <InvestmentHistory/>
    <div className='container'>
    
        {/* <div className="position-relative text-center card">
            <div className="position-absolute w-100" style={{height: 300}}>
                <div className="card-center">
                <h1 className="pb-5 mb-5 text-center">
                    <span className="font-24 d-block pt-4 mt-1">{getAmount(accBalance)}</span>
                    <span className="font-12 font-400 opacity-50 d-block mt-n2">Withdrawable Balance</span>
                </h1>
                </div>
            </div>
            <div className="mx-auto d-flex justify-content-center w-100 ">
                <div className="chart mx-auto no-click" id="chart-activity"/>
            </div>
        </div> */}
 
        <div className='row mt-2'>
        <div className='col-6'>
                <div className="card m-0 card-style bg-theme shadow-card shadow-card-m shadow-sm" style={{height: "180px", borderRadius: "8px"}}>
                    <div className="card-center d-flex justify-content-center">
                    <Chart options={options2} series={series2} className='color-theme' type="donut" height={180} />
                    </div>
                    <div className="card-overlay">
                        <div className='font-18 position-absolute top-50 start-50 translate-middle text-center'>
                            <h4>{getAmount(currentInvested)}</h4>
                            <div className='font-10 text-center fw-bolder color-theme'>Invested Amount</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-6'>
                <div className="card m-0 card-style bg-theme shadow-card shadow-card-m shadow-sm" style={{height: "180px", borderRadius: "8px"}}>
                    <div className="card-center d-flex justify-content-center">
                    <Chart options={options2} series={series2} className='color-theme' type="donut" height={180} />
                    </div>
                    <div className="card-overlay">
                        <div className='font-18 position-absolute top-50 start-50 translate-middle text-center'>
                            <h4>{getAmount(refBalance)}</h4>
                            <div className='font-10 text-center fw-bolder color-theme'>Referral Earning</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Quick Actions --> */}
    
    
    <div className='container' style={{marginTop: '10px'}}>
        <div className='row'>
            <div className='col-12'>
                <div className=' card mb-2' style={{height: '220px', overflow: 'hidden', borderRadius: '10px'}}>
                    <MiniChart symbol= "BINANCE:BTCUSDT"
                    width= "100%"
                    height= {220}
                    locale= "en"
                    dateRange= "12M"
                    colorTheme= "dark"
                    trendLineColor= "#13a753"
                    underLineColor= "rgba(14, 239, 111, 0.11)"
                    underLineBottomColor= "rgba(41, 98, 255, 0)"
                    isTransparent= {true}
                    autosize= {false}/>
                </div>
            </div>
        </div>
    </div>
    
    
      
</div>
  )
}

export default Index