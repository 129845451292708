import React, {useEffect, useState} from 'react';
import '../../components/Clock.css';
// import NotifierClock from '../Request/NotifierClock';
import {perSecondsEarning, currentEarning} from '../Request/Wallets';


const Clock = () => {
   
    let currentAmount = currentEarning();
    let earningRate = perSecondsEarning();
    
    const [date, setDate] = useState(new Date());
    const [earnedAmount, setEarnedAmount] = useState(currentAmount);
    const tick = () => setDate(new Date());
   

    const hoursDegrees =
    date.getHours() * 30 + date.getMinutes() / 2;

    const minutesDegrees =
        date.getMinutes() * 6 + date.getSeconds() / 10;
    const secondsDegrees = date.getSeconds() * 6;

    const divStyleHours = {
        transform: "rotateZ(" + hoursDegrees + "deg)"
    };

    const divStyleMinutes = {
        transform: "rotateZ(" + minutesDegrees + "deg)"
    };

    const divStyleSeconds = {
        transform: "rotateZ(" + secondsDegrees + "deg)"
    };
    
    useEffect(() => {    
        const keepTicking = setInterval(() => {
          tick();
          currentAmount += earningRate;
          setEarnedAmount(currentAmount);
        }, 1000);    
        return () => {
        clearInterval(keepTicking);
        }
    }, [])
  return (
      <div>
        {currentAmount > 0 &&
        <div className="color-theme text-center  font-weight-lighter font-18 mt-1"><strong className='mr-2 opacity-50'>Profit traded: </strong><span className='color-green-dark mx-2'>${earnedAmount.toFixed(5)}</span></div>
        }
      </div>
  )
}

export default Clock 