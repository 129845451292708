import React, {useState, useEffect} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
import InvestmentHistory from '../Investment/InvestmentHistory';
import {investmentsHistory} from '../Request/Histories';
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);
const getDate = (date) => new Date(date).toDateString();

const ConfirmPayments = () => {

    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    
    const initialImage = {
        image: null
    }

    const initialpayment = {
        investment_id: '',
    }
    const [investments, setInvestments] = useState(investmentsHistory());

    const newInvestmentsHistory = () => {
        let accountHistory = [];
        let storedHistory = localStorage.getItem('binaryfair-account-histories');
        if (storedHistory !== null) {
            let data = JSON.parse(storedHistory);
            accountHistory = data.investments;
        }
        return accountHistory;
    }

    const [paymentForm, setpaymentForm] = useState(initialpayment);
    const onChange = (e) =>
    setpaymentForm({ ...paymentForm, [e.target.name]: e.target.value });

    const [formImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...formImage, image: event.target.files[0]});
      }; 

      const { isLoading: isSendingRequest, mutate: postFunding } = useMutation(
        async () => {
            
		const formData = new FormData(); 
		formData.append('image', formImage.image);
		formData.append('investment_id', paymentForm.investment_id);
        return await apiClient.post('/api/approve-payment', formData);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess();  
            setTimeout(() => {
                window.location.href = '/';
              }, 5000);
                 
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

      useEffect(() => {
        
        window.addEventListener('updatedStorage', function (e) {
         setInvestments(newInvestmentsHistory());
   }, false);        
     });

  return (
    <div id="page-content">
        <div className='container mt-4'>
        <div className="card border border-fade-blue show fade p-0 mb-3">
            <div className="content my-3">
                <div className="d-flex">
                    <div className="">
                        <h2 className="text-center color-theme mb-0 font-500 font-24">
                            CONFIRM PAYMENT
                        </h2>
                        <p className='color-theme'>
                        kindly use the form below to confirm your payments
                        </p>
                    </div>
                </div>
            </div>
        </div>

            <div className="card p-2" style={{marginTop: '20px'}}>
            {formImage.image !== null &&
            <img style={{height: "80px"}} src={URL.createObjectURL(formImage.image)} alt="payment-image" className=" mx-auto"/>
            }

           


            <div className="pb-2"></div>
                {/* <span className='text-danger'>{error !== '' && error.country}</span> */}
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <select onChange={onChange} type="text" name="investment_id" className="form-control bg-theme color-theme rounded-xs" id="investment_id" placeholder='select investment'>
                    <option>Select investment</option>
                        {investments.length > 0 &&
                        investments.map(investment => (
                        <option style={{color: '#000000'}} value={investment.id}>{investment.status} payment of {getAmount(investment.amount)} on {getDate(investment.created_at)}</option>
                        ))
                        }
                    </select>
                    <label htmlFor="country" className="form-label-always-active color-highlight font-11">Investment</label>
                    <span className="font-10"></span>
                </div>
            <div className="pb-2"></div>


            <div className="pb-3"></div>
            <div className="text-center">
                <input onChange={onFileChange} type="file" id='profile' name="profile" accept="image/*" style={{display: "none"}}/>
                <label className='text-center' htmlFor='profile'>
                <span translate="no" className="material-icons-outlined color-theme" style={{fontSize: "38px"}} >add_a_photo</span><br/>
                <span className='text-warning'>Click the icon above to upload payment proof</span>
                </label>
                <span className="font-10"></span>
            </div>
            <div className="pb-3"></div>

            {formImage.image !== null && 
            <div onClick={postFunding} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
            {isSendingRequest ?<span>uploading...</span>
            :
            <span>Submit proof</span>
            }
            </div>
            }
            

        </div>
        </div>
        <InvestmentHistory/>
    </div>
  )
}

export default ConfirmPayments